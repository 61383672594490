<template>
    <div>
      <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
      <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #ffffff">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #ffffff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
    
    
      <v-layout wrap justify-center>
        <v-flex pt-5 xs11 sm11 md11 lg11 xl11 >
    
          <!-- Header Section -->
          <v-layout wrap justify-start class="my-3">
            <v-flex xs12 sm3 md3 lg10 text-start align-center pt-2 class="headingChurch">Logs</v-flex>
            <!-- <v-flex xs12 sm9 md9 lg2 text-end align-center ><v-btn class="churchbtn" color="blue" style="color:white"  @click="goToAddChurch">ADD CHURCH</v-btn></v-flex> -->
          </v-layout>
    
        
          <v-layout wrap justify-start>
            <v-flex xs12>
            
        
            
                <!-- Table section -->
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-data-table
                      :headers="headers"
                      :items="loglist"
                      hide-default-footer
                      class="elevation-1 text--black"
                      id="virtual-scroll-table"
                     
                     
                    >

                    <template v-slot:[`item.createdAt`]="{ item }">
                        <span>{{ formatDate1(item.createdAt) }}</span>
                      </template>

                      <template v-slot:[`item.updated_by.updatedAt`]="{ item }">
                        <span>{{ formatDate1(item.updated_by.updatedAt) }}</span>
                      </template>
                      <!-- <template v-slot:[`item._id`]="{ item }">
                        <v-icon small color="red" class="ml-4" @click.stop="openDeleteDialog(item)">
                          mdi-delete
                        </v-icon>
                      </template> -->
                    </v-data-table>
                  </v-flex>
                </v-layout>
                <!-- pagination -->
                <v-layout wrap justify-center pt-2>
                  <v-flex xs12>
                    <div class="text-center pb-5" v-if="pages > 0">
                      <v-pagination :length="pages" v-model="currentPage" color="primary white--text"
                        circle></v-pagination>
                    </div>
                  </v-flex>
                </v-layout>
             
            </v-flex>
          </v-layout>
          </v-flex>
          </v-layout>
          <!-- delete dialog -->
          <!-- <v-dialog v-model="dialog" max-width="600">
            <v-card class="dialog-card">
              <v-card-title class=" grey lighten-2 d-flex justify-center">
                <v-icon color="red" size="32">mdi-alert</v-icon>
                <span class="ml-2">Confirm Deletion</span>
              </v-card-title>
              <v-card-text class="py-5 text-center text-des">
                <div class="body-1">
                  Are you sure you want to delete this Church? 
                  <br />
                  This action <strong>cannot be undone</strong>.
                </div>
                <v-divider class="my-3"></v-divider>
                <v-row class="pt-2" no-gutters>
                  <v-col>
                    <img src="@/assets/images/church_icon.png" width="40px" height="40px" class="dialog-icon" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-center pa-2">
                <v-btn style="font-family: interBold;font-size:13px;color:white" color="#cf3a45"
                @click="dialog = false">Cancel</v-btn>
                <v-btn style="font-family: interBold;font-size:13px;color:white" color="blue darken-1"
                @click="confirmDelete">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
           -->
        </div>
    </template>
    
    <script>
    import axios from 'axios';
    export default {
      data() {
        return {
          appLoading: false,
          showSnackBar:false,
        timeout:5000,
        msg: "",
          deleteDialog: false, // Controls the dialog visibility
          keyword: "",
          
          loglist:[],
          dialog: false,
          itemToDelete: null,
          headers: [
            { text: "Updated By", value: "updated_by.name", width: "200px" },
     // { text: "Created At", value: "createdAt", width: "100px" },
            { text: "Update At", value: "createdAt", width: "200px" },
            // { text: "Church", value: "updated_by.phoneNumber", width: "200px" },
            { text: "Phone", value: "updated_by.phoneNumber", width: "200px" },
            { text: "Role", value: "updated_by.role", width: "100px" }
      // Add other headers as needed
    ],
          page: 1,
          currentPage: 1,
          pages: 0,
          limit: 10,
        };
      },
    //   watch:{
    //     currentPage() {
    //       this.getData();
    //     },
    //     keyword() {
    //     this.getData();
    //        },
  
    //        rite() {
    //     this.currentPage=1
        
          
    //     this.diocese="",
    //     this.selectedServicesNames="",
    //     this.parishType="",
    //     this.getData();
    //     },
    //     diocese() {
    //     this.currentPage=1
        
    //     this.selectedServicesNames="",
    //     this.parishType="",
  
    //     this.getData();
    //    },
  
    //    parishType() {
    //     this.selectedServicesNames="",
    //    this.getData();
    //    this.currentPage=1
    //  },
    //    selectedServicesNames() {
       
    //     this.getData();
    //     this.currentPage=1
    //   },
      
    //   },
      mounted() {
            this.getData(); 
            // this.getRite(); 
            // this.getDiocese();
            // this.getParishType();
        },
      methods: {
        formatDate1(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB').replace(/\//g, '-');
    },
        // redirectToViewPage(item) {
        //   this.$router.push('/singleLog?id=' + item._id);
        // },
    //     getRite() {
    //     this.appLoading = true;
    //     axios({
    //       url: "/get/rite/list",
    //       method: "GET",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         this.riteArray = response.data.data;
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  
    //   getDiocese() {
    //     this.appLoading = true;
    //     axios({
    //       url: "/get/diocese/list",
    //       method: "GET",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         this.dioceseArray = response.data.data;
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  
    //   getParishType() {
    //     this.appLoading = true;
    //     axios({
    //       url: "/get/parish/type/list",
    //       method: "GET",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         this.parishTypeArray = response.data.data;
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  
      
        getData() {
                this.appLoading = true;
             //   const currentTime = new Date().toISOString(); // Get current time in ISO format
                axios({
                    url: "/get/church/update/logs",
    
                    method: "GET",
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                    params: {
                        churchId:this.$route.query.id,
                      limit: this.limit,
                      page: this.currentPage,
                      
                    },
                })
                    .then((response) => {
                        this.appLoading = false;
                        this.loglist = response.data.data;
                        // this.pages = response.data.pages;
                        // this.totalData = response.data.count;
                        this.pages = Math.ceil(response.data.totalLength / this.limit);
                    })
                    .catch((err) => {
                        this.appLoading = false;
                        this.ServerError = true;
                        console.log(err);
                    });
            },
    
        //     openDeleteDialog(item) {
        //   this.itemToDelete = item;
        //   this.dialog = true;
        // },
        // confirmDelete() {
        //   if (this.itemToDelete) {
        //     this.deleteItem(this.itemToDelete);
        //   }
        //   this.dialog = false;
        // },
        // deleteItem(r) {
        //   var data = { id: r._id };
        //   axios({
        //     url: "/delete/church",
        //     method: "POST",
        //     data: data,
        //     headers: {
        //       token: localStorage.getItem("token"),
        //     },
        //   })
        //   .then((response) => {
        //     this.delete = false;
        //     this.appLoading = false;
        //     if (response.data.status) {
        //       this.msg = response.data.msg;
        //       this.showSnackBar = true;
        //       this.getData();
        //     } else {
        //       this.msg = response.data.msg;
        //       this.showSnackBar = true;
        //     }
        //   })
        //   .catch((err) => {
        //     this.appLoading = false;
        //     this.ServerError = true;
        //     console.log(err);
        //   });
        // },
      
        // goToAddChurch() {
        //   this.$router.push({ name: 'addChurch' });
        // },
        // editChurch() {
        //   this.$router.push({ name: 'editChurch' });
        // }
      },
      // computed: {
      //   filteredChurches() {
      //     return this.churches.filter((church) => {
      //       const matchesSearch =
      //         this.search === '' ||
      //         church.name.toLowerCase().includes(this.search.toLowerCase());
      //       const matchesRite =
      //         !this.selectedRite || church.rite === this.selectedRite;
      //       const matchesLanguage =
      //         !this.selectedLanguage || church.language === this.selectedLanguage;
      //       const matchesLocation =
      //         !this.selectedLocation || church.location === this.selectedLocation;
      //       const matchesType =
      //         !this.selectedType || church.type === this.selectedType;
    
      //       return (
      //         matchesSearch &&
      //         matchesRite &&
      //         matchesLanguage &&
      //         matchesLocation &&
      //         matchesType
      //       );
      //     });
      //   },
      // },
    };
    </script>
    
    <style scoped>
       
       
    .dialog-card {
      font-family: interbold;
      border-radius: 8px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    }
    
    .dialog-icon {
      animation: pulse 1s infinite alternate;
    }
    
    .dialog-button {
      min-width: 120px;
    }
    
    @keyframes pulse {
      from {
        transform: scale(1);
        opacity: 0.7;
      }
      to {
        transform: scale(1.1);
        opacity: 1;
      }
    }
    </style>
    